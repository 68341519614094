/*@import url( 'https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&family=Roboto+Mono:wght@200;300;400;500;600&family=Questrial&family=Albert+Sans:wght@200;300;400;500;600&display=swap' );*/
@import url( fonts.css );

html {
  height: 100%;
  background: black;
}

body {
  height: 100%;
  cursor: default;
  scroll-behavior: smooth;

  color: black;
  --max-width: 1920px;
  /*background-color: lime;*/
}

.isMobileSafari body {
  height: 100vh;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*text-decoration: none;*/
  color: currentColor;
}

.section .section {
  display: contents;
}

.section {
  --scrollbar-size: 0px;
  --section-padding: 64px;
  margin: 0 auto;
  width: 100%;
  max-width: var( --max-width );

  padding-right: calc( var( --section-padding ) - var( --scrollbar-size ) );
  padding-left: var( --section-padding );
}

.scroll {
  --scrollbar-size: 8px;
  height: 100%;
  overflow: auto scroll;
  display: flex;
  flex-direction: column;
}

.scroll::-webkit-scrollbar {
  width: var( --scrollbar-size );
  height: var( --scrollbar-size );
  /*background-color: #ddd;*/
  /*background-color: #000;*/
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #000;
  /*background-color: #ddd;*/
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

@media (max-width: 640px) {
  .section {
    --section-padding: 16px;
  }

  .scroll {
    --scrollbar-size: 0px;
  }
}

#root {
  position: relative;
  background: white;
  height: 100%;
  width: 100vw;
}

/* ===================================== */
#modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}


.isMobileSafari #root {
  height: 100vh;
}

#modal > * {
  pointer-events: initial;
}




.popup-root {
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: Inter, sans-serif;
}






.popup-root > * {
  position: absolute;
  pointer-events: initial;
}

#tooltip-root {
  /*display: none;*/
  /*width: calc( 100% + 1000px );*/
}

.black-theme {
  font-family: 'Inter', sans-serif;
  background-color: var( --color-dark-background );
  color: var( --color-white );
  font-size: 12px;
}

:root {
  /* old */
  --color-toolbar-dark-background: #333333;
  --color-hover-dark: #0C0C0C;
  --color-blue: #1A73E8;
  --color-light-blue: #368DFF;
  --color-white: #FFFFFF;
  --color-dirty-white: #EBEBEB;
  --color-helper-icon: #A9A9A9;
  --color-start-frame: #F5ABAB;
  --color-dark-background: #1E1E1E;
  --color-modal-window-background: rgba( 0, 0, 0, 0.6 );
  --color-light-grey-stroke: #8A8989;
  --color-dark-grey-stroke: #47484e;

  --color-website-black: #222222;
  --color-mistake: #E84331;

  /* new */
  --text-default: #111;
  --website-black: #222;
  --text-inverse: #fff;
  --helper-icon: #a9a9a9;
  --text-muted: rgba(17, 17, 17, 0.50);
  --background-inverse: #f4f2ed;
  --dirty-white: #ebebeb;
  --green: #398039;
  --border-default: rgba(17, 17, 17, 0.15);
}

svg {
  flex-shrink: 0;
}

html {
  /*font-family: 'Inter', sans-serif;*/
  color: var( --website-black );
  font-family: Roboto Mono, monospace;
}

.main-section {
  --scrollbar-size: 0px;
  --main-section-padding: 64px;

  padding-right: calc( var( --main-section-padding ) - var( --scrollbar-size ) );
  padding-left: var( --main-section-padding );
  height: 100%;
  overflow: hidden;
  overflow-x: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.main-section > * {
  max-width: calc( 1920px - var( --main-section-padding ) * 2 );
  width: 100%;
  margin: 0 auto;
}

/*.main-section.scroll {*/
/*  --scrollbar-size: 8px;*/
/*  overflow-y: scroll;*/
/*}*/

/*.main-section.scroll::-webkit-scrollbar {*/
/*  width: var( --scrollbar-size );*/
/*  height: var( --scrollbar-size );*/
/*  background-color: #ddd;*/
/*}*/

/*.main-section.scroll::-webkit-scrollbar-thumb {*/
/*  background-color: #000;*/
/*}*/

/*.main-section.scroll::-webkit-scrollbar-thumb:hover {*/
/*  background-color: #aaa;*/
/*}*/

/*@media (max-width: 640px) {*/
/*  .main-section {*/
/*    --main-section-padding: 16px;*/
/*  }*/

/*  .main-section.scroll {*/
/*    --scrollbar-size: 2px;*/
/*  }*/
/*}*/


/* presets */
.flex-row,
.flex-row-rev,
.flex-column,
.flex-column-rev,
.flex-center,
.justify-content-center,
.justify-content-start,
.justify-content-end,
.justify-content-stretch,
.justify-content-between,
.justify-content-around,
.align-items-center,
.align-items-start,
.align-items-end,
.align-items-stretch { display: flex; }

.flex-row        { flex-direction: row; }
.flex-row-rev    { flex-direction: row-reverse; }
.flex-column     { flex-direction: column; }
.flex-column-rev { flex-direction: column-reverse; }

.flex-center,
.justify-content-center  { justify-content: center; }
.justify-content-start   { justify-content: flex-start; }
.justify-content-end     { justify-content: flex-end; }
.justify-content-stretch { justify-content: stretch; }
.justify-content-between { justify-content: space-between; }
.justify-content-around  { justify-content: space-around; }

.flex-center,
.align-items-center  { align-items: center; }
.align-items-start   { align-items: flex-start; }
.align-items-end     { align-items: flex-end; }
.align-items-stretch { align-items: stretch; }

.max-size,
.max-width { width: 100%; }

.max-size,
.max-height { height: 100%; }

.fit-cover   { object-fit: cover; }
.fit-contain { object-fit: contain; }

.position-top,
.position-right,
.position-bottom,
.position-left,
.position-absolute { position: absolute; }
.position-relative { position: relative; }
.position-fixed    { position: fixed; }

.position-top    { top: 0; }
.position-right  { right: 0; }
.position-bottom { bottom: 0; }
.position-left   { left: 0; }

.d-none { display: none; }
.d-flex { display: flex; }

.bg-lime    { background: lime; }
.bg-red     { background: red; }
.bg-blue    { background: blue; }
.bg-orange  { background: orange; }
.bg-yellow  { background: yellow; }
.bg-green   { background: green; }
.bg-magenta { background: magenta; }
.bg-purple  { background: purple; }
.bg-black   { background: black; }
.bg-white   { background: white; }